import type { FieldContext } from 'vee-validate'

type InteractionEventGetter = (ctx: FieldContext) => string[]

// Validates on submit only
const passiveMode = () => [] as string[]

function lazyMode () {
  return ['change']
}

const aggressiveMode = () => ['input']

function eagerMode ({ errorMessage }: FieldContext) {
  if (errorMessage.value)
    return ['input']

  return ['change']
}

const modes: Record<string, InteractionEventGetter> = {
  passive: passiveMode,
  lazy: lazyMode,
  aggressive: aggressiveMode,
  eager: eagerMode,
}
export default function useVeeModes () {
  return {
    modes,
  }
}
